import { ProductCard } from '~/shared/components';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import useCustomer from '~/features/commerce-api/hooks/useCustomer';
import { useWishlist } from '~/features/commerce-api/hooks/wishlist/useWishList';
import { SFProduct } from '~/features/product-details-page/pdp.definitions';
import {
    generateAlgoliaConfigProps,
    _transformAlgoliaToProductDetails,
} from '~/features/productList/Algoliga.utils';
import { M30ProductCarouselModule } from '~/lib';
import { useProductHandler } from '~/services/search/useProductHandler';
import { CardCarousel } from '~/shared/components/CardCarousel/CardCarousel';
import { TProductDetails } from '~/shared/components/ProductCard/ProductCard.definition';
import { useAlgoliaQuery } from '~/shared/hooks/useAlgoliaQuery';
import { useEvents } from '~/shared/hooks/useEvents';
import { useUtils } from '~/shared/hooks/useUtils';
import { useFrame } from '~/shared/utils';
import { useTranslation } from '~/shared/utils/translation';
import { ModuleContainer } from '../ModuleContainer';
import { _transformProductDetailsToDataLayer } from '~/shared/hooks/useEvents/helpers';
import { CardCarouselLoader } from '~/shared/components/CardCarouselLoader/CardCarouselLoader';
import { GTM_LIST_ID, GTM_LIST_NAME } from '~/shared/hooks/useEvents/useGTMEvents/models';

export type M30Props = M30ProductCarouselModule;

export const M30ProductCarouselBlock = ({
    headline,
    algoliaFilterType,
    algoliaFilter,
    callToAction,
    subHeadline,
    priority,
    ...rest
}: M30Props) => {
    const { data: frame } = useFrame();
    const { handleFavourite, isFavorite } = useWishlist();
    const { productsImpressionsEvent } = useEvents(frame);
    const { translate } = useTranslation();
    const { convertPriceToLocale } = useUtils();
    const productHandler = useProductHandler();
    const { isPlusMember } = useCustomer();

    const filter = generateAlgoliaConfigProps({
        filter: algoliaFilter ?? '',
        filterType: algoliaFilterType,
    });

    filter.filters = filter.filters + ' AND cust_in_stock_web:true';

    const { inView, ref } = useInView({
        threshold: 0,
        rootMargin: '-100px 0px',
    });
    const {
        data: products,
        isSuccess,
        isLoading,
    } = useAlgoliaQuery<{ hits: SFProduct[] }, TProductDetails[]>(
        {
            ...filter,
            hitsPerPage: 10,
        },
        {
            queryKey: [JSON.stringify(filter)],
            select: (data) => {
                return data.hits.map((hit) =>
                    _transformAlgoliaToProductDetails(
                        hit,
                        translate,
                        convertPriceToLocale,
                        frame?.productSettings,
                    ),
                ) as TProductDetails[];
            },
            enabled: !!inView,
        },
    );

    useEffect(() => {
        if (products && products.length > 0) {
            productsImpressionsEvent(
                GTM_LIST_ID.PRODUCT_CAROUSEL,
                `${GTM_LIST_NAME.CAROUSEL} - ${headline?.text}`,
                products?.map((item, index) =>
                    _transformProductDetailsToDataLayer(item, 1, index, isPlusMember),
                ),
            );
        }
    }, [products]);

    return (
        <ModuleContainer {...rest} ref={ref}>
            {isSuccess && !isLoading && products && products.length > 0 && (
                <CardCarousel
                    options={{
                        slidesPerView: 'auto',
                        spaceBetween: 24,
                    }}
                    headline={headline}
                    subHeadline={subHeadline}
                    callToAction={callToAction}
                    items={products?.map((p, i) => (
                        <ProductCard
                            {...p}
                            priority={priority}
                            position={i}
                            listId={GTM_LIST_ID.PRODUCT_CAROUSEL}
                            listName={`${GTM_LIST_NAME.CAROUSEL} - ${headline?.text}`}
                            onFavourite={handleFavourite}
                            onBuyBtn={productHandler}
                            isFavourite={isFavorite(p.id)}
                        />
                    ))}
                />
            )}
            {!isSuccess && isLoading && <CardCarouselLoader />}
        </ModuleContainer>
    );
};
